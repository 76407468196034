@use 'sass:math';

@import url('https://fonts.googleapis.com/css?family=Inria+Serif:400,700|Open+Sans:400,400i,700,700i&display=swap&subset=latin-ext');
@import '_mixins';

$color-black: #333;
$color-gray: #f0f0f0;
$color-white: #fff;

$color-yellow: yellow;
$color-blue: blue;
$color-red: red;
$color-green: lightgreen;

$color-body: $color-white;
$color-layout: transparent;
$color-box: $color-gray;
$color-highlight: $color-red;

$color-border-light: #999;
$color-border-dark: aqua;

$color-heading-1: $color-yellow;
$color-heading-2: $color-green;

$font-family-text: 'Inria Serif', serif;
$font-family-heading: 'Open Sans', sans-serif;
$font-size-base: 12;
$font-size-h1: 26;

body {
  margin: 0;
  font-family: $font-family-text;
  font-size: math.div($font-size-base, 12) * 100%;
  line-height: 1.5;
  color: $color-black;
  background: $color-body;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

a {
  color: $color-blue;

  &:hover,
  &:focus {
    color: $color-red;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-heading;
  font-size: 100%;
  font-weight: normal;
  font-style: italic;
  line-height: 1;
}

.layout {
  max-width: 35em;
  margin: 6em auto;
  margin-bottom: 1em;
  background: $color-layout;
}
.layout__header {
  margin: 2em 0;
}
.layout__description {
  color: $color-highlight;
}
.layout__heading {
  font-size: math.div($font-size-h1, $font-size-base) * 100%;
  font-weight: bold;
  text-align: center;

  a {
    padding: 0 0.5em;
    color: $color-white;
    background: linear-gradient($color-heading-1, $color-heading-2);
    text-shadow: 0 0 3px $color-black;
    text-decoration: none;
  }
}
.layout__nav {
  ul {
    margin: 2em 0;
  }
  li {
    line-height: 0.8;
    color: $color-black;
    padding: 0;
    list-style-type: none;

    a {
      display: inline-block;
      color: $color-black;
      text-decoration: underline;
      border-bottom: 0.5em solid transparent;
    }
    a:hover,
    a:focus,
    &.active a {
      text-decoration: none;
      border-bottom-color: $color-black;
    }

    &:before {
      content: '';
      display: inline-block;
      width: 1em;
    }
    &.active:before {
      content: '\2b50';
    }
  }
}
.layout__badge {
  text-align: right;
}

.index {
  padding: 0;
  margin: 0;
}
.index--pinned {
  margin-bottom: 1em;
  border-bottom: 1px dashed $color-highlight;
}
.index__item,
.page,
.post {
  display: block;
  background: $color-box;
  margin: 1em;
  padding: 1em;
  padding-bottom: 0.5em;
  border-right: 0.5em solid $color-border-light;
  border-bottom: 0.5em solid $color-border-dark;
}
.index__item__title,
.page__title,
.post__title {
  margin: 0;
}
.index__item__content,
.page__content,
.post__content {
  padding-left: 0.5em;

  img {
    max-width: 100%;
  }
  p,
  ul,
  ol,
  > div,
  > iframe {
    margin: 0.5em 0;
  }
}

.index__item--text {
  margin-right: 4em;
}
.index__item--audio {
  margin-right: 3em;
}
.index__item--video {
  margin-right: 2em;
}
